/* eslint-disable */
import LoanCard from "components/loan-card/LoanCard";
import { useGetMyLoansQuery } from "pages/application/applicationService";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { solid } from "@fortawesome/fontawesome-svg-core/import.macro";
import "./my-loans.scss";
import Footer from "components/footer/Footer";
import { getCurrentUserId } from "features/authSlice";
import { useSelector } from "react-redux";
import { useLocation, useNavigate } from "react-router-dom";
import { formatDate } from "utilities/date";
import { useDispatch } from "react-redux";
import { useEffect } from "react";
import { setLoanInfo } from "features/authSlice";
import Overlay from "components/ui/loading-spinners/Overlay/Overlay";

export default function MyLoans() {
  const userId = useSelector(getCurrentUserId);
  const { data, isLoading, isFetching } = useGetMyLoansQuery(userId);
  const location = useLocation();
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const pathsToShowMyLoans = ["/application", "/dashboard"];
  console.log(data?.length);
  
  useEffect(() => {
    if (data && data.length == 1) {
      if (
        !(
          (
            location.state != null &&
            typeof location.state === "object" &&
            "previousPath" in location.state &&
            pathsToShowMyLoans.some((str) => location.state.previousPath.includes(str))
          ) // eslint-disable-line
        )
      ) {
        dispatch(setLoanInfo(data[0]));
        console.log(dispatch(setLoanInfo(data[0])))
        navigate("/post-auth", { replace: true });
      }
    }
  }, [data]);

  if (isFetching || isLoading) {
    return <Overlay />;
  } else return (
    <div className="loans-section">
      <div className="loans-section__wrapper">
        <div className="loans-section__header">
          <div className="loans-section__header-icon-bg">
            <FontAwesomeIcon icon={solid("file-pen")} className="loans-section__header-icon" />
          </div>
          <span className="loans-section__header-title">My Loans</span>
        </div>
        <div className="loans-section__card-container">
          {data?.map((item, index) => {
            return (
              <div className="loans-section__card" key={index}>
                <LoanCard
                  nhDealApplicationId={item.nhDealApplicationId}
                  property={item.property}
                  loanPurpose={item.loanPurpose}
                  loanAmount={item.loanAmount}
                  status={item.status}
                  startedDate={formatDate(item.startedDate)}
                  nhApplicationId={item.nhApplicationId}
                  applicationRoleType={item.applicationRoleType}
                  dealApplicationId={item.dealApplicationId}
                  nhApplicationStatus={item.nhApplicationStatus}
                  hasInterimDashboard={false}
                />
              </div>
            );
          })}
        </div>
      </div>
      <Footer />
    </div>
  );
}
