import Overlay from "components/ui/loading-spinners/Overlay/Overlay";
import { getRoutingData } from "features/authSlice";
import { useDeleteFailedDocumentsMutation } from "pages/dashboard/home/dashboardService";
import { useEffect } from "react";
import { useSelector } from "react-redux";
import { useNavigate } from "react-router-dom";
import { ApplicationStatusNames } from "utilities/Constant";
import { UserFeature, UserVersion, userVersionFeatureMap } from "utilities/UserversionFeatures";
import { getLoanInfo } from "features/authSlice";

const getRouteForCompletedApplication = (version: string) => {
  const versionFeatures = userVersionFeatureMap[version.toUpperCase() as UserVersion];
  const destination = versionFeatures?.includes(UserFeature.SHOW_DASHBOARD) ? "/dashboard" : "/application";
  return destination;
};

const getRouteForLOS = (version: string) => {
  const versionFeatures = userVersionFeatureMap[version.toUpperCase() as UserVersion];
  const destination = versionFeatures?.includes(UserFeature.SHOW_DASHBOARD) ? "/dashboard" : "/los-auth";
  return destination;
};

const getRouteForApplicationPath = (nhApplicationId?: string): string => {
  return nhApplicationId ? "/application" : "/path";
};

export default function AuthRouting() {
  const navigate = useNavigate();
  const { version } = useSelector(getRoutingData);
  const [removeFailedDocuments, { isSuccess: isDeleteFailedDoucumentsSuccess }] = useDeleteFailedDocumentsMutation();
  const authRoutingData = useSelector(getLoanInfo);
  const performRouting = () => {
    if (authRoutingData) {

      if (authRoutingData.nhApplicationStatus && authRoutingData.nhApplicationStatus.toLowerCase() === ApplicationStatusNames.Completed.toLowerCase()) {
        navigate(getRouteForCompletedApplication(version), { replace: true });
        return;
      }

      if (authRoutingData.hasInterimDashboard) {
        navigate(getRouteForLOS(version), { replace: true });
        return;
      } else {
        navigate(getRouteForApplicationPath(authRoutingData.applicationId), { replace: true });
        return;
      }

    }
  };

  useEffect(() => {
    if (isDeleteFailedDoucumentsSuccess) {
      performRouting();
    }
  }, [isDeleteFailedDoucumentsSuccess]);
  useEffect(() => {
    void (async () => {
      authRoutingData.nhDealApplicationId && await removeFailedDocuments(authRoutingData.nhDealApplicationId);
    })();
  }, []);

  return <Overlay />;
}
