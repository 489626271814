export const currentDate = new Date();

export const getCurrentMonthAndYear = () => {
  return [currentDate.getMonth() + 1, currentDate.getFullYear()];
};
const [thisMonth, thisYear] = getCurrentMonthAndYear();
export const makeMonthOptions = (selectedYear: number) =>
  Array.from(
    {
      length: selectedYear === thisYear ? thisMonth : 12,
    },
    (_, i) => ((i + 1).toString().length === 1 ? `0${i + 1}` : `${i + 1}`)
  );

export const makeYearOptions = (selectedMonth: number) => {
  const years = Array.from({ length: 100 }, (_, i) => `${thisYear - i}`);
  if (selectedMonth > thisMonth) {
    years.shift();
  }
  return years;
};

export const getYearsBeforeCurrentYear = (yearsBeforeCurrentYear: number) => {
  const date = new Date();
  date.setFullYear(date.getFullYear() - yearsBeforeCurrentYear);
  return date;
};

export const getDateAsMMDDYYYYString = (date?: Date) => {
  if (!date) return "";
  const month = date.getMonth() + 1;
  const day = date.getDate();
  const year = date.getFullYear();
  const mm = month.toString().length === 1 ? `0${month}` : month;
  const dd = day.toString().length === 1 ? `0${day}` : day;
  return `${mm}/${dd}/${year}`;
};

const monthNames = ["January", "February", "March", "April", "May", "June", "July", "August", "September", "October", "November", "December"];

export const getMonthName = (date: Date) => {
  return monthNames[date.getMonth()];
};
export const formatDate = (dateString: string): string => {
  const date = new Date(dateString);
  const month = String(date.getUTCMonth() + 1).padStart(2, "0");
  const day = String(date.getUTCDate()).padStart(2, "0");
  const year = date.getUTCFullYear();
  return `${month}/${day}/${year}`;
};
export function subtractDates(date1: Date, date2: Date): number {
  return (new Date(date1).getTime() - new Date(date2).getTime()) / 1000;
}

export const hoursToSecondsConvertion = (hours: number): number => hours * 60 * 60;

export const minutesToSecondsConvertion = (minutes: number): number => minutes * 60;

export const addMinutesToCurrentDate = (date: Date, minutes: number): Date => new Date(date.getTime() + minutes * 60 * 1000);
