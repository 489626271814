import { Button } from "components/ui/button/Button";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { solid } from "@fortawesome/fontawesome-svg-core/import.macro";
import { useDispatch } from "react-redux";
import { useNavigate } from "react-router-dom";
import "./loan-card.scss";
import classNames from "classnames";
import { IconProp } from "@fortawesome/fontawesome-svg-core";
import { ApplicationLoanStatus, MyLoanInfo } from "pages/application/applicationService";
import { setLoanInfo} from "features/authSlice";

interface LoanDetailProps {
  label: string;
  value: string;
  showTooltip?: boolean;
}

const LoanDetail = ({ label, value, showTooltip }: LoanDetailProps) => (
  <div className="loanCard-wrapper__detail">
    <span className="loanCard-wrapper__keyName">{label}:</span>
    <span className="loanCard-wrapper__value" title={showTooltip ? value : undefined}>
      {value}
    </span>
  </div>
);

const getStatusIcon = (status: ApplicationLoanStatus) => {
  const icons: Record<ApplicationLoanStatus, IconProp> = {
    new: solid("flag-checkered"),
    incomplete: solid("hexagon-exclamation"),
    started: solid("paper-plane"),
    processing: solid("paper-plane"),
    submittedToUnderwriting: solid("paper-plane"),
    underReview: solid("paper-plane"),
    conditionallyApproved: solid("paper-plane"),
    finalReview: solid("paper-plane"),
    clearedToClosed: solid("paper-plane"),
    funded: solid("piggy-bank"),
  };

  return <FontAwesomeIcon icon={icons[status]} />;
};
const formatStatus = (status: string) => {
  const spacedStatus = status.replace(/([a-z])([A-Z])/g, "$1  $2");
  const formatted = spacedStatus.charAt(0).toUpperCase() + spacedStatus.slice(1);
  return formatted.replace(/\bTo\b/g, "to");
};

export default function LoanCard(loanInfo: MyLoanInfo) {
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const statusClass = classNames({
    greeen: loanInfo.status === "new",
    red: loanInfo.status === "incomplete",
    blue: ["started", "processing", "submittedToUnderwriting", "conditionallyApproved", "underReview", "finalReview", "clearedToClosed"].includes(loanInfo.status),
    grey: loanInfo.status === "funded",
  });
  const handleClick = () => {
    dispatch(setLoanInfo(loanInfo));
    navigate("/post-auth", { replace: true });
  };
  return (
    <div className="loanCard">
      <div className="loanCard-wrapper">
        <div className={`loanCard-wrapper__status ${statusClass}`}>
          <span>{getStatusIcon(loanInfo.status)}</span>
          <span>{formatStatus(loanInfo.status)}</span>
        </div>
        <LoanDetail label="Property" value={loanInfo.property} />
        <LoanDetail label="Loan Purpose" value={loanInfo.loanPurpose} />
        <LoanDetail label="Amount" value={loanInfo.loanAmount} />
        <LoanDetail label="Started" value={loanInfo.startedDate} />
        <div className="loanCard-wrapper__doc-notify">{loanInfo.documentStatus ? "New document available" : ""}</div>
        <div className="loanCard-wrapper__selectBtn">
          <Button title="Select" onClick={handleClick} />
        </div>
      </div>
    </div>
  );
}
