import { createSelector, createSlice } from "@reduxjs/toolkit";
import type { PayloadAction } from "@reduxjs/toolkit";
import {
  TokensResponse,
  ValidateOtpResponse,
} from "pages/authentication/authenticationService";
import { MyLoanInfo } from "pages/application/applicationService";
import { LoanStatusType } from "pages/dashboard/home/dashboardService";
import type { RootState } from "store";
import { addMinutesToCurrentDate } from "utilities/date";

export interface UserState {
  userName: string;
  accessToken: string;
  userId: string;
  applicationId: string | undefined;
  status: string | undefined;
  losFile: boolean;
  tokenValidationParams: TokenValidation;
  version: string;
  inquiryId: string | undefined;
  dealApplicationId: string | undefined;
  nhDealApplicationId: string | undefined;
  dealId: string | undefined;
  prospectId: string | undefined;
  applicationRoleType: string;
  isLogin: boolean;
  loanStatus?: LoanStatusType | undefined;
  leadProvider: string;
  loanPurpose: string;
  property: string;
  loanAmount: string;
  startedDate: string;
  documentStatus: boolean | undefined;
  hasLoanNumber: boolean;
  nhApplicationStatus: string | undefined;
  hasInterimDashboard: boolean | undefined;
}

interface TokenValidation {
  refreshToken: string;
  lastActiveTime: Date;
  tokenExpiration: Date;
}

const initialState: UserState = {
  userName: "",
  accessToken: "",
  userId: "",
  applicationId: "",
  status: "",
  losFile: false,
  tokenValidationParams: {
    refreshToken: "",
    lastActiveTime: new Date(0),
    tokenExpiration: new Date(0),
  },
  version: "",
  inquiryId: "",
  dealApplicationId: "",
  nhDealApplicationId: "",
  dealId: "",
  prospectId: "",
  applicationRoleType: "",
  isLogin: false,
  loanStatus: "Started",
  leadProvider: "",
  loanPurpose: "",
  property: "",
  loanAmount: "",
  startedDate: "",
  documentStatus: false,
  hasLoanNumber: false,
  nhApplicationStatus: undefined,
  hasInterimDashboard: undefined,
};

export const authSlice = createSlice({
  name: "auth",
  initialState,
  reducers: {
    setCredentials: (state, action: PayloadAction<ValidateOtpResponse>) => {
      const { name, accessToken, id, refreshToken, version } = action.payload;
      const dateTimeStamp = new Date();
      state.userName = name;
      state.accessToken = accessToken;
      state.userId = id;
      state.tokenValidationParams.refreshToken = refreshToken;
      state.tokenValidationParams.lastActiveTime = dateTimeStamp;
      state.tokenValidationParams.tokenExpiration = addMinutesToCurrentDate(
        dateTimeStamp,
        10
      );
      state.version = version;
    },
    setLogout: () => initialState,
    setApplicationId: (state, action: PayloadAction<string>) => {
      state.applicationId = action.payload;
    },
    setNhDealApplicationId: (state, action: PayloadAction<string>) => {
      state.nhDealApplicationId = action.payload;
    },
    setTokens: (state, action: PayloadAction<TokensResponse>) => {
      state.accessToken = action.payload.accessToken;
      state.tokenValidationParams.refreshToken = action.payload.refreshToken;
      state.tokenValidationParams.tokenExpiration =
        action.payload.tokenExpireTime;
      state.tokenValidationParams.lastActiveTime = new Date();
    },
    setLastActiveSession: (state, action: PayloadAction<Date>) => {
      state.tokenValidationParams.lastActiveTime = action.payload;
    },
    setInquiryId: (state, action: PayloadAction<string>) => {
      state.inquiryId = action.payload;
    },
    setUserId: (state, action: PayloadAction<string>) => {
      state.userId = action.payload;
    },
    setIsLogin: (state, action: PayloadAction<boolean>) => {
      state.isLogin = action.payload;
    },
    setLeadProvider: (state, action: PayloadAction<string>) => {
      state.leadProvider = action.payload;
    },
    setLoanInfo: (state, action: PayloadAction<MyLoanInfo>) => {
      const {
        nhDealApplicationId,
        property,
        loanPurpose,
        loanAmount,
        status,
        startedDate,
        documentStatus,
        nhApplicationId,
        applicationRoleType,
        dealApplicationId,
        nhApplicationStatus,
        hasInterimDashboard,
      } = action.payload;
      state.nhDealApplicationId = nhDealApplicationId;
      state.loanPurpose = loanPurpose;
      state.status = status;
      state.applicationId = nhApplicationId;
      state.loanAmount = loanAmount;
      state.property = property;
      state.startedDate = startedDate;
      state.documentStatus = documentStatus;
      state.applicationRoleType = applicationRoleType;
      state.dealApplicationId = dealApplicationId;
      state.nhApplicationStatus = nhApplicationStatus;
      state.hasInterimDashboard = hasInterimDashboard;
    },
  },
});

export const {
  setCredentials,
  setLogout,
  setApplicationId,
  setTokens,
  setLastActiveSession,
  setInquiryId,
  setUserId,
  setIsLogin,
  setNhDealApplicationId,
  setLeadProvider,
  setLoanInfo,
} = authSlice.actions;

export const getCurrentUserName = (state: RootState): string =>
  state.auth.userName;
export const getCurrentAccessToken = (state: RootState): string =>
  state.auth.accessToken;
export const getCurrentUserId = (state: RootState): string => state.auth.userId;
export const getCurrentUserApplicationId = (state: RootState): string =>
  state.auth.applicationId ?? "";
export const getCurrentUserApplicationStatus = (state: RootState): string =>
  state.auth.status ?? "";
export const getLosFileStatus = (state: RootState): boolean =>
  state.auth.losFile;
export const getTokenValidationParameters = (
  state: RootState
): TokenValidation => state.auth.tokenValidationParams;
export const getVersion = (state: RootState): string => state.auth.version;
export const getUserId = (state: RootState): string => state.auth.userId;
export const getDealApplicationId = (state: RootState): string =>
  state.auth.dealApplicationId ?? "";
export const getDealId = (state: RootState): string => state.auth.dealId ?? "";
export const getProspectId = (state: RootState): string =>
  state.auth.prospectId ?? "";
export const getUserRole = (state: RootState): string =>
  state.auth.applicationRoleType ?? "";
export const getLoanPurpose = (state: RootState): string =>
  state.auth.loanPurpose ?? "";
export const getRoutingData = createSelector(
  (state: RootState) => state.auth,
  ({
    version,
    losFile,
    userId,
    status,
    applicationRoleType,
    isLogin,
    inquiryId,
  }) => ({
    version,
    losFile,
    userId,
    status,
    applicationRoleType,
    isLogin,
    inquiryId,
  })
);
export const getLeadProvider = (state: RootState): string =>
  state.auth.leadProvider;
export const getNhDealApplicationId = (state: RootState): string =>
  state.auth.nhDealApplicationId ?? "";
export const getLoanInfo = createSelector(
  (state: RootState) => state.auth,
  ({
    loanPurpose,
    status,
    applicationId,
    loanAmount,
    property,
    startedDate,
    documentStatus,
    applicationRoleType,
    dealApplicationId,
    hasInterimDashboard,
    nhApplicationStatus,
    nhDealApplicationId,
  }) => ({
    loanPurpose,
    status,
    applicationId,
    loanAmount,
    property,
    startedDate,
    documentStatus,
    applicationRoleType,
    dealApplicationId,
    hasInterimDashboard,
    nhApplicationStatus,
    nhDealApplicationId,
  })
);

export default authSlice.reducer;
